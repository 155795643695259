
import { Component, Prop, Vue, Watch, Emit } from "vue-property-decorator";
import { FormSelect, FormDate } from "@/components/forms";

interface VisitMoment {
    date: string;
    duration: string;
}

@Component({
    components: {
        FormDate,
        FormSelect,
    },
})
export default class FormVisitMoments extends Vue {
    @Prop() value?: VisitMoment[] | null;

    @Prop({ required: true }) name!: string;

    @Prop({ default: false }) label!: string;

    @Prop({ default: false }) disabled!: boolean;

    localValue: VisitMoment[] = [
        {
            date: "",
            duration: "",
        },
    ];

    durationOptions = [
        { label: "1 uur", value: 60 },
        { label: "2 uur", value: 120 },
        { label: "3 uur", value: 180 },
    ];

    addVisitMoment() {
        this.localValue!.push({
            date: "",
            duration: "",
        });
    }

    removeVisitMoment(index: number) {
        this.localValue!.splice(index, 1);
    }

    @Emit("input")
    handleInput() {
        return this.localValue;
    }

    @Watch("value", { immediate: true })
    valueChanged(newValue: VisitMoment[] | null) {
        this.localValue = newValue ?? [
            {
                date: "",
                duration: "",
            },
        ];
    }
}
