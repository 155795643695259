import { render, staticRenderFns } from "./visit-moments.vue?vue&type=template&id=2fe59217&scoped=true"
import script from "./visit-moments.vue?vue&type=script&lang=ts"
export * from "./visit-moments.vue?vue&type=script&lang=ts"
import style0 from "./visit-moments.vue?vue&type=style&index=0&id=2fe59217&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2fe59217",
  null
  
)

export default component.exports